@font-face {
    font-family: GuDianMingChaoTi;
    font-weight: bold;
    src: url('./assets/font/GuDianMingChaoTi.ttf');
}


#root::before {
    @apply fixed top-0 left-0 w-screen h-screen;

    z-index: -1;
    background-position: center;
    background-size: cover;
    background-color: var(--black);
    content: '';
    background-image: url('./assets/images/background.jpg');
}


#root {
    @apply min-h-screen overflow-x-hidden select-none text-gray-200 tracking-wide bg-no-repeat bg-fixed;

    overflow: auto;
    padding-left: 33.333333vw;
    width: 100vw;
    height: 100vh;
    background-color: var(--black-lighter);
    transition: all 0.3s ease;
}


@media (max-width: 1024px) {
    #root {
        padding-left: 0;
    }
}

/* page fade animation */
.fade {
    @apply w-full self-start;

    animation: fade 0.8s ease-out;
}
@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.fade-in-appear,
.fade-in-enter {
    opacity: 0;
    transform: translateY(-3rem);
}
.fade-in-appear-active,
.fade-in-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.6s ease-in-out;
}
.fade-in-exit {
    opacity: 1;
    transform: translateY(0);
}
.fade-in-exit-active {
    opacity: 0;
    transform: translateY(-3rem);
    transition: all 0.6s ease-in-out;
}

/* custom page */
.page {
    @apply relative w-full min-h-screen pt-8 lg:pt-12 pb-8 lg:pb-12 px-4 md:px-8 lg:pr-32 lg:pl-0 flex items-center select-text;
}

/* link */
.link {
    @apply relative;

    color: var(--blue-light);
}
.link::after {
    @apply absolute left-1/2 bottom-0 w-0 h-px bg-current
    transform transition-all duration-500 -translate-x-1/2;

    content: '';
}
.link:hover::after {
    @apply w-full;
}
.link::before {
    @apply absolute top-0 left-0 py-1 px-2 z-10 text-sm break-normal whitespace-nowrap rounded shadow-inner
    origin-bottom-left transform transition-all duration-500 opacity-0 scale-50 -translate-y-4;

    content: attr(href);
    color: var(--blue-light);
    background-color: var(--black);
}
.link:hover::before {
    @apply opacity-100 scale-100 -translate-y-7;
}
.link-defalut {
    color: var(--white);
}
.link-defalut:hover {
    color: var(--blue-light);
}
.markdown-body-scrollable {
    background: #ff6600;
}


.required::before {
    display: inline-block;
    margin-right: 4px;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    color: #ff4d4f;
    line-height: 1;
    content: '*';
}
.textOver {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.textOver-2 {
    overflow:hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;

}

.textOver-3 {
    overflow:hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;

}

.textOver-4 {
    overflow:hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    display: -webkit-box;
    -webkit-box-orient: vertical;

}
.radio-vertical {
    .ant-radio-wrapper {
        display: block;
    }
}
.checkbox-vertical {
    .ant-checkbox-wrapper {
        display: flex;
        margin-right: 0;
    }
}